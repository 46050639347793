import { getPosts } from '../../api/contentfulApi';
import * as R from 'ramda';

export const RECEIVE_SERVICES = 'RECEIVE_SERVICES';
export const RECEIVE_SUBSERVICES = 'RECEIVE_SUBSERVICES';
export const RECEIVE_THANKYOUPAGE = 'RECEIVE_THANKYOUPAGE';
export const RECEIVE_LANDINGPAGE = 'RECEIVE_LANDINGPAGE';
export const RECEIVE_LAYOUT = 'RECEIVE_LAYOUT';
export const SAVE_LOCALE = 'SAVE_LOCALE';
export const CLEAR_DATA_CMS = 'CLEAR_DATA_CMS';

const BRAND = process.env.REACT_APP_BRAND;

/**
 * Save locale
 */
export const saveLocale = locale => dispatch => {
  dispatch({
    type: SAVE_LOCALE,
    locale,
  });
};

const getChainContent = results => {
  let chainContent;

  pageLoop: for (let i = 0; i < results.length; i++) {
    const chains = R.path(['chain'], R.path(['fields'], results[i]));

    for (let j = 0; j < chains.length; j++) {
      if (R.path(['title'], R.path(['fields'], chains[j])).toLowerCase() === BRAND) {
        chainContent = R.path(['fields'], results[i]);
        break pageLoop;
      }
    }
  }

  return chainContent;
};

/**
 * Get Services from Contentful
 */
export const getServices = () => dispatch => {
  getPosts('service')
    .then(results => {
      Promise.all([
        dispatch({
          type: RECEIVE_SERVICES,
          services: results,
        }),
      ]);
    })
    .catch(error => {
      Promise.all([
        dispatch({
          type: `${RECEIVE_SERVICES}_FAILURE`,
          error,
        }),
      ]);
    });
};

/**
 * Get SubServices from Contentful
 */
export const getSubServices = () => dispatch => {
  getPosts('subservice')
    .then(results => {
      Promise.all([
        dispatch({
          type: RECEIVE_SUBSERVICES,
          subServices: results,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${RECEIVE_SUBSERVICES}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Get Thank You Page content from Contentful
 */
export const getThankYouPage = () => dispatch => {
  getPosts('thankYouPage')
    .then(results => {
      const thankYouPageContents = getChainContent(R.path(['items'], results));

      Promise.all([
        dispatch({
          type: RECEIVE_THANKYOUPAGE,
          thankYouPage: thankYouPageContents,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${RECEIVE_THANKYOUPAGE}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Get Landing page content from Contentful
 */
export const getLandingPage = () => dispatch => {
  getPosts('landingPage')
    .then(results => {
      const landingPageContents = getChainContent(R.path(['items'], results));

      Promise.all([
        dispatch({
          type: RECEIVE_LANDINGPAGE,
          landingPage: landingPageContents,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${RECEIVE_LANDINGPAGE}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Get Brand content from Contentful
 */
export const getLayout = () => dispatch => {
  getPosts('brand')
    .then(results => {
      const layout = R.path(
        ['fields'],
        R.head(R.path(['items'], results).filter(item => R.path(['fields', 'name'], item) === BRAND)),
      );

      Promise.all([
        dispatch({
          type: RECEIVE_LAYOUT,
          layout: layout,
        }),
      ]);
    })
    .catch(error => {
      return Promise.all([
        dispatch({
          type: `${RECEIVE_LAYOUT}_FAILURE`,
          error,
        }),
      ]);
    });
};

/**
 * Clear data
 */
export const clearDataCms = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_CMS,
  });
};
