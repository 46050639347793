import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
  backButton: {
    width: '100%',
    fontWeight: 'normal',
    textTransform: 'initial',
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      width: 'initial',
      fontSize: '15px',
    }
  },
});

/**
 * Backbutton
 * @param {Object} classes     Classes from JSS inlineStyles
 * @param {Object} to          Link address
 * @param {Object} forward     Show arrow on right side of text
 */

const BackButton = ({classes, to, forward, t}) => {

  return forward ? (
    <Button
      disableRipple
      color="primary"
      className={classes.backButton}
      endIcon={<ArrowForwardIcon />}
      component={NavLink}
      to={to}
    >
      {t('views.backButton.backToFrontpage')}
    </Button>
  )
  :
  (
    <Button
      disableRipple
      color="primary"
      className={classes.backButton}
      startIcon={<ArrowBackIcon />}
      component={NavLink}
      to={to}
    >
      {t('views.backButton.back')}
    </Button>
  )


}

export default withStyles(inlineStyles)(withTranslation('translations')(BackButton));

