import React from 'react';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
  anchor: {
    textDecoration: 'none',
  },
  ctaBoxRed: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: '20px',
    marginBottom: '20px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 40,
      paddingBottom: '40px',
      paddingTop: '40px',
    }
  },
  headline: {
    paddingBottom: '30px',
    [theme.breakpoints.up('md')]: {
      fontSize: 22,  
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 28,
    },
  },
  headlineMobile: {
    paddingBottom: '10px'
  },
  price: {
    [theme.breakpoints.up('md')]: {
      fontWeight: 'bold',
      fontSize: '72px',
      paddingBottom: '15px'
    },
  },
});

/**
 * RedCta
 * Use to show CTA with red background
 * @param {Object} classes     Classes from JSS inlineStyles
 * @param {Object} content     Content object (headline, caption, price)
 */

const RedCta = ({classes, content}) => {

  const {headline, caption, price, targetUrl} = content;

  return(
    <a href={targetUrl && targetUrl} className={classes.anchor}>
      <div className={classes.ctaBoxRed}>
        <Hidden smDown>
          <Typography
            variant="h2"
            className={classes.headline}
          >
            {headline}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography
            variant="h2"
            className={classes.headlineMobile}
          >
            {headline} {price}
          </Typography>
        </Hidden>
        <Hidden smDown>
          <Typography
            variant="h3"
            className={classes.price}
          >
            {price}
          </Typography>
        </Hidden>
        <Typography
          variant="body2"
          className={classes.p}
        >
          {caption && caption}
        </Typography>
      </div>
    </a>
  )

}

export default withStyles(inlineStyles)(withTranslation('translations')(RedCta));