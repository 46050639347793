/**
 * UserException : Create an exception class UserException for throwing
 * @param {string} code       Error object property : code (not documented)
 * @param {string} message    Error object property : message
 */
export class UserException {
  constructor(code, message) {
    this.message = message;
    this.code = code;
  }
  // Make the exception convert to a pretty string when used as
  // a string (e.g. by the error console)
  // override
  toString() {
    return `${this.code}: "${this.message}"`;
  }
}

/**
 * Do a fetch request to supplied URL
 */
const doRequest = async () => {

  try {

    const response = await fetch(`/.netlify/functions/ipStackRequest`);
    return response.json();

  } catch (err) {
    throw new UserException(err.code, err.message);
  }

}


/**
 * Get User Geolocation
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getUserGeolocation = async (values) => {

  try {

    const res = await doRequest();
    return res;

  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};