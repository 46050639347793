import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
    heading: {
        fontWeight: '400',
        margin: '15px 0 10px'
    },
    button: {
        marginTop: 20,
    }
});

/**
 * Cta
 * @param {Object} classes     Classes from JSS inlineStyles
 * @param {Object} headline 
 * @param {Object} caption 
 * @param {Object} buttonCaption
 * @param {Object} image  
 */

const Cta = ({classes, headline, caption, image, buttonCaption, buttonUrl}) => {

  return(
    <article className={classes.paddingLeftRight}>
        <img src={image} alt={headline} />
        <Typography variant="h4" color="primary" className={classes.heading}>
            {headline}
        </Typography>
        <Typography variant="body2">
            {caption}
        </Typography>
        <Button disableRipple variant="outlined" component={Link} to={buttonUrl} className={classes.button}>
            {buttonCaption}
        </Button>
    </article>
  )
}

export default withStyles(inlineStyles)(withTranslation('translations')(Cta));

