import React from 'react';
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import {getPageTitle} from '../../utils/SEO';

const inlineStyles = theme => ({

  paddingLeftRight: {
    paddingLeft: theme.spacing.body, 
    paddingRight: theme.spacing.body,
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  h1: {
    textAlign: 'center'
  }
});

/**
 * Error
 * Use to display error state of the program
 * @param {Object} classes    Classes from JSS inlineStyles
 * @param {Object} t          Translations
 */

const Error = ({classes, t}) => {

    return(
      <Grid container direction="row" spacing={0} >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle(t('views.error'))}</title>
        </Helmet>
        <Grid item xs={12} className={classes.paddingLeftRight}>
          <Typography
            variant="h1"
            className={classes.h1}
          >
            {t('error.headline')}
          </Typography>
          <Typography
            variant="body1"
          >
            {t('error.content')}
          </Typography>
        </Grid>
      </Grid>
    )

}

export default withStyles(inlineStyles)(withTranslation('translations')(((Error))));