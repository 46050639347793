import React, { Component } from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Icon from '@material-ui/core/Icon';
import { Field, reduxForm, submit, change, untouch, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import SelectedServiceBox from '../subcomponents/SelectedServiceBox';
import AvailableTime from '../subcomponents/AvailableTime';
import BackButton from '../subcomponents/BackButton';

import {
  getAvailability,
  getSpecialists,
  clearAvailability,
  clearFetchTimeStamp,
  clearMonthAvailability,
  clearAvailableOpticians,
  setAvailabilityStartDate,
} from '../../store/actions/availabilityActions';
import {
  trackTimeFilterMorning,
  trackTimeFilterDay,
  trackTimeFilterAfternoon,
  trackTimeFilterEvening,
  trackSaveUrlFragments,
  trackUrlChange,
  trackAvailableTimes,
} from '../../store/actions/trackingActions';

import { validate, returnField } from '../../utils/FormHelpers';

import { getPageTitle } from '../../utils/SEO';

const inlineStyles = theme => ({
  button: {
    width: '100%',
    textTransform: 'initial',
    fontSize: '14px',
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
      fontSize: '15px',
    },
  },
  expansionPanel: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    '&:before': {
      opacity: '0',
    },
  },
  expansionPanelSummary: {
    padding: 0,
  },
  details: {
    padding: 0,
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing.body,
    paddingRight: theme.spacing.body,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  availableTimes: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  form: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgb(242,247,250)',
      paddingTop: 30,
    },
  },
  field: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
  },
  content: {
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      marginBottom: 20,
    },
  },
  buttonGroupButton: {
    color: theme.palette.primary.secondary,
    fontSize: 11,
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
  },
  buttonGroup: {
    marginBottom: 20,
    marginTop: 15,
  },
  h3: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  serviceBox: {
    [theme.breakpoints.down('sm')]: {
      order: -1,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
    },
  },
  chooseLocationAndTime: {
    paddingLeft: 0,
    paddingRight: 31,
    [theme.breakpoints.down('sm')]: {
      order: -1,
    },
  },
  body1: {
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    marginRight: '20px',
  },
});

let timeout = null;

/**
 * SelectService
 * Filtering view to find an instance of a service (e.g. 13.30 @ Aleksanterinkatu Helsinki)
 * @param {Object} classes             Classes from JSS inlineStyles
 * @param {function} handleSubmit      Submit handling function
 * @param {function} getAvailability   Get Availability function
 * @param {function} clearAvailability Clear Availability function
 * @param {object} availableTimes      All available times
 * @param {object} history             History object for redirecting
 * @param {Object} t                   Translations
 */

class SelectService extends Component {
  state = {
    timeFilter: moment('07:00', 'HH:mm'),
    updateTimeStamp: Date(),
    stateTimeSlots: [],
  };

  componentDidMount() {
    const { remoteSubmit } = this.props;
    this.selectTimeOfDayFilter(moment('07:00', 'HH:mm'));
    remoteSubmit();
  }

  componentWillUnmount() {
    clearTimeout(timeout);
  }

  selectTimeOfDayFilter = time => {
    this.setState({
      timeFilter: time,
    });
  };

  getOpticianNameById = (id, availableOpticians) => {
    if (!id || availableOpticians.length === 0) return '';

    const correctOptician = availableOpticians.filter(optician => optician.key === id.toString());

    return correctOptician.length > 0 ? R.path(['value'], correctOptician[0]) : '';
  };

  render() {
    const {
      trackTimeFilterMorning,
      trackTimeFilterDay,
      trackTimeFilterAfternoon,
      trackTimeFilterEvening,
      trackAvailableTimes,
      classes,
      handleSubmit,
      getAvailability,
      getSpecialists,
      availableTimes,
      availabilityCallInProgress,
      specialistCallInProgress,
      clearFetchTimeStamp,
      fetchTimeStamp,
      fetchedMonthAvailabilities,
      stores,
      history,
      reservationDate,
      cities,
      selectedCity,
      selectedStore,
      selectedOptician,
      selectedServiceBox,
      storesGroupedByCities,
      opticiansAvailable,
      storesWhereAvailable,
      setAvailabilityStartDate,
      i18n,
      t,
      lang,
    } = this.props;
    const { timeFilter, updateTimeStamp, stateTimeSlots } = this.state;

    let timeSlots = [];

    moment.locale(lang);

    if (lang === 'fi') {
      moment.updateLocale('fi', {
        weekdays: [
          'sunnuntaina',
          'maanantaina',
          'tiistaina',
          'keskiviikkona',
          'torstaina',
          'perjantaina',
          'lauantaina',
        ],
      });
    }

    const getLocale = () => i18n.language;

    let availableTimesFiltered = availableTimes;

    let filteredCities = cities;

    if (storesWhereAvailable && storesWhereAvailable.length > 0) {
      let citiesFilteredByStore = Object.values(storesGroupedByCities).filter(
        city =>
          city.filter(store => storesWhereAvailable.includes(R.path(['attributes', 'name', 'fi'], store))).length > 0,
      );

      filteredCities = R.uniq(citiesFilteredByStore.map(city => R.path(['city'], city[0])));
    }

    if (availableTimes) {
      const match = availableTimes.filter(
        item => item.attributes.date === moment(reservationDate).format('YYYY-MM-DD'),
      );
      if (match.length > 0) {
        // Available times were found on selected day
        const timeSlotsPerStore = match.map(item => item.attributes.timeSlots);

        const opticiansPerStore = match.map(item => item.attributes.opticianCode);

        // Concatenate together
        let allStoresTimeSlots = [];
        allStoresTimeSlots = allStoresTimeSlots.concat(...timeSlotsPerStore);
        timeSlots = allStoresTimeSlots;
      } else {
        // No available times on selected day.
        timeSlots = [];
      }
    }

    const minutesOfDay = function (m) {
      return m.minutes() + m.hours() * 60;
    };

    // Display timeslots based on user defined startTime & store filters
    let timeSlotsFiltered = timeSlots.filter(item => {
      const startTimeParsed = moment(item.startTime, 'YYYY-MM-DDTHH:mm:ss');

      const isAfter = minutesOfDay(startTimeParsed) >= minutesOfDay(timeFilter);

      if (selectedStore) {
        const matchStore = JSON.parse(selectedStore).key === item.storeId;
        return isAfter && matchStore && item;
      } else {
        return isAfter && item;
      }
    });

    // Filter calendar display if store is selected
    if (selectedStore) {
      availableTimesFiltered = availableTimesFiltered.filter(item => item.storeId === JSON.parse(selectedStore).key);
    }

    // Sort timeslots based on their startTime
    const sortByFirstItem = R.sortBy(R.prop('startTime'));
    timeSlotsFiltered = sortByFirstItem(timeSlotsFiltered);

    let storesWithinSelectedCity = [];

    if (selectedCity && Array.isArray(storesGroupedByCities[selectedCity])) {
      if (storesWhereAvailable && storesWhereAvailable.length > 0) {
        storesGroupedByCities[selectedCity].filter(item => {
          if (storesWhereAvailable.includes(R.path(['attributes', 'name', 'fi'], item))) {
            storesWithinSelectedCity.push({
              key: R.path(['id'], item),
              value: R.path(['attributes', 'name', lang], item),
            });
          }
        });
      } else {
        storesWithinSelectedCity = storesGroupedByCities[selectedCity].map(item => ({
          key: R.path(['id'], item),
          value: R.path(['attributes', 'name', lang], item),
        }));
      }

      // Rearrange stores alphabetically
      storesWithinSelectedCity.sort((a, b) => a.value.localeCompare(b.value));
    }

    // Get opticians per store and map for selected field
    let opticiansMappedForSelectField = [];

    opticiansMappedForSelectField = opticiansAvailable.filter(
      (item, index, self) => index === self.findIndex(optician => optician.key === item.key),
    ); // Avoid duplicates

    if (selectedStore) {
      opticiansMappedForSelectField = opticiansAvailable.filter(item => item.storeId === JSON.parse(selectedStore).key);
    }

    opticiansMappedForSelectField.sort((a, b) => a.value.localeCompare(b.value));

    let pageTitle = [R.path(['headline'], selectedServiceBox)];

    if (selectedCity) {
      pageTitle.push(selectedCity);

      if (
        JSON.stringify(timeSlotsFiltered) !== JSON.stringify(stateTimeSlots) ||
        (fetchTimeStamp && fetchTimeStamp > updateTimeStamp)
      ) {
        trackAvailableTimes(timeSlotsFiltered);
        this.setState({ updateTimeStamp: fetchTimeStamp });
        this.setState({ stateTimeSlots: timeSlotsFiltered });
        clearFetchTimeStamp();
      }
    }

    if (selectedStore) {
      pageTitle.push(JSON.parse(selectedStore).value);
    }

    const requestAbortController = (React.useRef < AbortController) | (null > null);

    const handleMonthChange = month => {
      if (requestAbortController.current) {
        // make sure that you are aborting useless requests
        // because it is possible to switch between months pretty quickly
        requestAbortController.current.abort();
        return;
      }

      if (fetchedMonthAvailabilities.includes(month.format('MM-YYYY'))) {
        // Do no fetch availabilities again if they have been fetched already but save current viewed month.
        setAvailabilityStartDate(month.format('YYYY-MM-DD'));
        return;
      }

      const values = {
        city: selectedCity,
        person: selectedOptician,
        store: selectedStore,
        availabilityStartDate: month.format('YYYY-MM-DD'),
      };

      getAvailability(values);
    };

    return (
      <Grid container direction="row" spacing={0}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle(pageTitle)}</title>
        </Helmet>
        <Grid item xs={12} className={classes.chooseLocationAndTime}>
          <Typography variant="h1" className={classes.h1 + ' ' + classes.paddingLeftRight}>
            {t('views.selectService.chooseLocationAndTime')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} className={classes.form}>
          <form className={classes.content} onSubmit={handleSubmit(getAvailability)}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Field
                  key="city"
                  id="city"
                  name="city"
                  component={returnField('select')}
                  label={t('views.selectService.city')}
                  type="text"
                  selOpts={filteredCities}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.field}>
                <Field
                  key="store"
                  id="store"
                  name="store"
                  component={returnField('select')}
                  label={t('views.selectService.store')}
                  placeholder={t('views.selectService.selectStore')}
                  type="text"
                  disabled={!selectedCity}
                  selOpts={storesWithinSelectedCity}
                  optionsAreObjects={true}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  disabled={!selectedCity}
                  key="person"
                  id="person"
                  name="person"
                  component={returnField('select')}
                  label={t('views.selectService.specialist')}
                  type="text"
                  selOpts={opticiansMappedForSelectField}
                  fullWidth
                  placeholder={t('views.selectService.selectOptician')}
                  optionsAreObjects={true}
                  dataFetchInProgress={specialistCallInProgress}
                  onOpen={() => {
                    if (selectedCity != undefined && opticiansMappedForSelectField.length === 0) {
                      const values = {
                        city: selectedCity,
                        store: selectedStore,
                      };

                      getSpecialists(values);
                    }
                  }}
                />
              </Grid>
              {selectedCity && (
                <Grid item xs={12} md={6} style={{ position: 'relative', display: 'table' }}>
                  <Field
                    key="reservationDate"
                    id="reservationDate"
                    name="reservationDate"
                    component={returnField('date')}
                    label={t('views.selectService.day')}
                    type="date"
                    lang={lang}
                    fullWidth
                    disabled={availabilityCallInProgress}
                    availableTimes={
                      availableTimesFiltered && Array.isArray(availableTimesFiltered) && availableTimesFiltered
                    }
                    handleMonthChange={handleMonthChange}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12} md={4} className={classes.serviceBox}>
          <SelectedServiceBox content={selectedServiceBox} />
        </Grid>

        <Grid item xs={12} md={8} className={classes.availableTimes}>
          <Typography variant="h3" gutterBottom className={classes.h3}>
            {t('views.selectService.freeSlots')} {moment(reservationDate).format('dddd D.M.YYYY')}
          </Typography>
          <ButtonGroup
            color="secondary"
            aria-label="outlined secondary button group"
            fullWidth
            className={classes.buttonGroup}>
            <Button
              disableRipple
              variant={moment('07:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
              onClick={() => {
                trackTimeFilterMorning();
                this.selectTimeOfDayFilter(moment('07:00', 'HH:mm'));
              }}
              className={classes.buttonGroupButton}>
              {t('views.selectService.morning')} (7:00-)
            </Button>
            <Button
              disableRipple
              variant={moment('11:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
              onClick={() => {
                trackTimeFilterDay();
                this.selectTimeOfDayFilter(moment('11:00', 'HH:mm'));
              }}
              className={classes.buttonGroupButton}>
              {t('views.selectService.day')} (11:00-)
            </Button>
            <Button
              disableRipple
              variant={moment('15:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
              onClick={() => {
                trackTimeFilterAfternoon();
                this.selectTimeOfDayFilter(moment('15:00', 'HH:mm'));
              }}
              className={classes.buttonGroupButton}>
              {t('views.selectService.afternoon')} (15:00-)
            </Button>
            <Button
              disableRipple
              variant={moment('18:00', 'HH:mm').isSame(timeFilter) ? 'contained' : 'outlined'}
              onClick={() => {
                trackTimeFilterEvening();
                this.selectTimeOfDayFilter(moment('18:00', 'HH:mm'));
              }}
              className={classes.buttonGroupButton}>
              {t('views.selectService.evening')} (18:00-)
            </Button>
          </ButtonGroup>
          <Divider />
        </Grid>
        <Grid item xs={12} md={8} className={classes.paddingLeftRight}>
          {!availabilityCallInProgress &&
            timeSlotsFiltered &&
            timeSlotsFiltered.length > 0 &&
            timeSlotsFiltered.map((item, index) => (
              <AvailableTime
                key={`${index}${item.key}${item.storeId}${item.appointmentType}`}
                day={moment(reservationDate).format('dddd D.M.YYYY')}
                storeId={item.storeId}
                appointmentType={item.appointmentType}
                stores={stores}
                history={history}
                content={item}
                lang={lang}
                selectedOptician={selectedOptician ? JSON.parse(selectedOptician).key : item.opticians[0]}
                selectedOpticianName={
                  selectedOptician
                    ? JSON.parse(selectedOptician).value
                    : this.getOpticianNameById(item.opticians[0], opticiansMappedForSelectField)
                }
              />
            ))}
          {!availabilityCallInProgress && (!timeSlotsFiltered || timeSlotsFiltered.length < 1) && (
            <Typography variant="body1" gutterBottom style={{ marginTop: '20px' }}>
              {t('views.selectService.noFreeSlots')}
            </Typography>
          )}
          {availabilityCallInProgress && (
            <div className={classes.progressContainer}>
              <CircularProgress className={classes.circularProgress} />
              <Typography variant="body1" gutterBottom style={{ marginTop: '20px' }}>
                Ladataan...
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} className={classes.paddingLeftRight}>
          <BackButton to={`/${getLocale()}`} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('selectService');

  const initialValues = {
    reservationDate: moment(R.path(['availability', 'currentTime'], state)).format('YYYY-MM-DD'),
  };

  // Initialize form with url params
  const cityParam = R.path(['match', 'params', 'city'], ownProps);
  const storeParam = R.path(['match', 'params', 'store'], ownProps);
  if (cityParam) {
    const selectedCity = R.path(['availability', 'storesGroupedByCitiesByUrlKey', cityParam], state);
    const firstStore = R.head(selectedCity);
    const cityName = R.path(['city'], firstStore);
    initialValues.city = cityName;
  }
  if (cityParam && storeParam) {
    const selectedCity = R.path(['availability', 'storesGroupedByCitiesByUrlKey', cityParam], state);
    const selectedStore = R.head(selectedCity.filter(item => item.url === storeParam));
    const storeKey = R.path(['id'], selectedStore);
    const storeName = R.path(['attributes', 'name', R.path(['contentful', 'locale'], state)], selectedStore);
    initialValues.store = JSON.stringify({ key: storeKey, value: storeName });
  }

  return {
    availableTimes: R.path(['availability', 'available_time_slots'], state),
    storesWhereAvailable: R.path(['availability', 'storesWhereAvailable'], state),
    availabilityCallInProgress: R.path(['availability', 'availabilityCallInProgress'], state),
    specialistCallInProgress: R.path(['availability', 'specialistCallInProgress'], state),
    fetchTimeStamp: R.path(['availability', 'fetchTimeStamp'], state),
    reservationDate: R.path(['form', 'selectService', 'values', 'reservationDate'], state),
    cities: R.path(['availability', 'cities'], state),
    stores: R.path(['availability', 'stores'], state),
    storesGroupedByCities: R.path(['availability', 'storesGroupedByCities'], state),
    opticiansAvailable: R.path(['availability', 'opticiansAvailable'], state),
    selectedCity: selector(state, 'city'),
    selectedStore: selector(state, 'store'),
    selectedOptician: selector(state, 'person'),
    selectedServiceBox: {
      headline: R.path(['availability', 'subService'], state),
      description: R.path(['availability', 'subServiceDescription'], state),
      price: R.path(['availability', 'price'], state),
    },
    initialValues,
    fetchedMonthAvailabilities: R.path(['availability', 'fetchedMonthAvailabilities'], state),
  };
};

const mapDispatchToProps = dispatch => ({
  getAvailability: values => dispatch(getAvailability(values)),
  getSpecialists: values => dispatch(getSpecialists(values)),
  clearAvailability: () => dispatch(clearAvailability()),
  clearMonthAvailability: () => dispatch(clearMonthAvailability()),
  clearAvailableOpticians: () => dispatch(clearAvailableOpticians()),
  setAvailabilityStartDate: values => dispatch(setAvailabilityStartDate(values)),
  remoteSubmit: () => dispatch(submit('selectService')),
  trackTimeFilterMorning: () => dispatch(trackTimeFilterMorning()),
  trackTimeFilterDay: () => dispatch(trackTimeFilterDay()),
  trackTimeFilterAfternoon: () => dispatch(trackTimeFilterAfternoon()),
  trackTimeFilterEvening: () => dispatch(trackTimeFilterEvening()),
  trackSaveUrlFragments: values => dispatch(trackSaveUrlFragments(values)),
  trackUrlChange: () => dispatch(trackUrlChange()),
  trackAvailableTimes: values => dispatch(trackAvailableTimes(values)),
  clearFetchTimeStamp: () => dispatch(clearFetchTimeStamp()),
});

const FormDecoratedComponent = reduxForm({
  form: 'selectService',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: true,
  onChange: (values, dispatch, props, previousValues) => {
    // User has changed city or store -> update url
    if (
      R.path(['city'], previousValues) !== R.path(['city'], values) ||
      R.path(['store'], previousValues) !== R.path(['store'], values)
    ) {
      const urlReplaceNordicChars = string => {
        return string
          .replace(/ä/g, 'a')
          .replace(/Ä/g, 'A')
          .replace(/ö/g, 'o')
          .replace(/Ö/g, 'O')
          .replace(/å/g, 'a')
          .replace(/Å/g, 'A')
          .replace(/ /g, '-')
          .replace(/,/g, '-');
      };

      let cityandstore = '';
      const storeParsed = R.path(['store'], values) ? JSON.parse(R.path(['store'], values)).value : null;
      const citySlugified = R.path(['city'], values) && urlReplaceNordicChars(R.path(['city'], values)).toLowerCase();
      const storeSlugified = storeParsed && urlReplaceNordicChars(storeParsed).toLowerCase();

      if (R.path(['city'], values)) {
        cityandstore = storeParsed ? `/${citySlugified}/${storeSlugified}` : `/${citySlugified}`;
      } else {
        cityandstore = '';
      }

      // Alter url based on user selections
      window && window.history.replaceState(null, '', `/${props.lang}/${props.match.params.slug}${cityandstore}`);
      props.trackSaveUrlFragments({
        service: props.match.params.slug ? props.match.params.slug : '',
        city: citySlugified ? citySlugified : '',
        store: storeSlugified ? storeSlugified : '',
      });
      props.trackUrlChange();
      props.clearMonthAvailability();
    }

    if (R.path(['person'], previousValues) !== R.path(['person'], values)) {
      props.clearMonthAvailability();
    }

    // Clear selected store on city change or if city is not defined.
    if (R.path(['city'], previousValues) !== R.path(['city'], values) || !R.path(['city'], values)) {
      props.clearAvailableOpticians();
      dispatch(change('selectService', 'store', ''));
      dispatch(untouch('selectService', 'store'));
    }

    // Clear selected person on store change or if store is not defined.
    if (R.path(['store'], previousValues) !== R.path(['store'], values)) {
      dispatch(change('selectService', 'person', ''));
      dispatch(untouch('selectService', 'person'));
    }

    // City has remained the same as in the previous values object
    // There is no IMMEDIATE need to fetch availability again unless some conditions are met
    // Also we would want to fetch the availability to reflect the current actual state if the browsing has lasted long without any data update

    if (R.path(['city'], previousValues) === R.path(['city'], values)) {
      // Did the person change? And did it change to an actual person? If yes, fire a specialist call.
      if (R.path(['person'], previousValues) !== R.path(['person'], values) && R.path(['person'], values)) {
        props.clearAvailability();
        props.getAvailability(values);
      }

      // Did the person change but not to an actual person?
      if (R.path(['person'], previousValues) !== R.path(['person'], values) && !R.path(['person'], values)) {
        props.clearAvailability();
        props.getAvailability(values);
      }

      // Did the store change? We then need to get the opticians for this store.
      if (R.path(['store'], previousValues) !== R.path(['store'], values)) {
        props.clearAvailability();
        props.getAvailability(values);
      }
    } else {
      // Clear availability on city change
      props.clearAvailability();

      // Clear selected store on city change
      dispatch(change('selectService', 'store', ''));
      dispatch(change('selectService', 'person', ''));
      dispatch(untouch('selectService', 'store'));
      dispatch(untouch('selectService', 'person'));

      props.getAvailability(values);
    }
  },
  onSubmit: (values, dispatch, props) => {
    props.getAvailability(values);
  },
})(SelectService);

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)),
);
