import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as instrumentariumBrandStyles from './instrumentarium/brandStyles';
import * as nissenBrandStyles from './nissen/brandStyles';
import * as keopsBrandStyles from './keops/brandStyles';

/**
 * Global theme
 * Here you can override any component styles
 * Override specifications can be found in Material UI docs
 */

let brandStyles = {};

if (process.env.REACT_APP_BRAND === 'nissen') {
  brandStyles = nissenBrandStyles;
} else if (process.env.REACT_APP_BRAND === 'keops') {
  brandStyles = keopsBrandStyles;
} else {
  brandStyles = instrumentariumBrandStyles;
}

let theme = createTheme({
  typography: {
    fontFamily: brandStyles.typography.fontFamilyBody,
    fontSize: 12,
    em: {
      fontFamily: brandStyles.typography.fontFamilyBody,
      fontWeight: 'bold',
    },
    strong: {
      fontFamily: brandStyles.typography.fontFamilyBody,
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      light: brandStyles.colors.palette.primary.light,
      main: brandStyles.colors.palette.primary.main,
      dark: brandStyles.colors.palette.primary.dark,
      contrastText: brandStyles.colors.palette.primary.contrastText,
    },
    secondary: {
      light: brandStyles.colors.palette.secondary.light,
      main: brandStyles.colors.palette.secondary.main,
      dark: brandStyles.colors.palette.secondary.dark,
      contrastText: brandStyles.colors.palette.secondary.contrastText,
    },
    background: {
      paper: brandStyles.colors.palette.background.paper,
    },
    status: {
      error: 'rgb(254, 34, 19)'
    },
  },
  footer: {
    backgroundColor: brandStyles.components.footer.backgroundColor,
  },
  overrides: {
    /* Styles overrides for material-ui-pickers datepicker modal */
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersCalendar: {
      week: {
        marginBottom: '2px',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000',
        paddingLeft: '2px',
        paddingTop: '4px',
      },
      current: {
        color: '#000',
      },
      daySelected: {},
    },
    /* Override all input field paddings */
    MuiInputBase: {
      root: {
        backgroundColor: brandStyles.colors.palette.background.paper,
      },
      input: {
        paddingTop: '20px !important',
        paddingBottom: '16px !important',
      },
      inputMultiline: {
        padding: '0px !important',
      },
    },
    MuiSelect: {
      root: {
        paddingTop: '15px !important',
        paddingBottom: '11px !important',
      },
    },
    /* Override mui button border colour and contained & secondary button colour for buttonGroup use */
    MuiButtonBase: {
      root: {
        boxShadow: 'none !important',
      },
    },
    MuiIconButton: {
      root: {
        backgroundColor: 'transparent !important',
        borderRadius: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        marginLeft: '0px !important',
        lineHeight: '18px',
        color: '#000 !important',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '2rem',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: brandStyles.typography.mobile.fontSizeBody2,
      },
    },
    MuiFormLabel: {
      root: {
        color: brandStyles.colors.palette.fontColor.body,
        fontSize: brandStyles.typography.desktop.fontSizeBody1,
        lineHeight: 'initial !important',
      },
    },
    /* Match Instru.fi toolbar left padding */
    MuiToolbar: {
      gutters: {
        paddingLeft: '32px !important',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgba(0,0,0,0.23) !important',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: brandStyles.colors.button.outlined.hover.backgroundColor,
        },
        '&.Mui-selected': {
          backgroundColor: brandStyles.colors.button.selected.backgroundColor,
          color: brandStyles.colors.button.selected.color,

          '&:hover': {
            backgroundColor: brandStyles.colors.button.selected.hover.backgroundColor,
            color: brandStyles.colors.button.selected.hover.color,
          },
        },
      },
    },
    MuiPickersBasePicker: {
      container: {
        alignItems: 'center',
      },
      pickerView: {
        overflow: 'auto',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginBottom: 12
      },
    },
  },
});

/**
 * Define responsive overrides
 */
theme.overrides.MuiToolbar = {
  regular: {
    [theme.breakpoints.up('md')]: {
      minHeight: '96px !important',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '96px !important',
      width: '100%',
    },
  },
  gutters: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '32px !important',
    },
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'center',
    },
  },
};

theme.overrides.MuiExpansionPanelSummary = {
  content: {
    margin: '30px 0 !important',
  },
  root: {
    padding: '0 15px 0 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 30px 0 30px',
    },
  },
};

theme.overrides.MuiExpansionPanelDetails = {
  root: {
    padding: '0 15px 15px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 30px 30px 30px',
    },
  },
};

theme.overrides.MuiButton = {
  label: {
    alignItems: 'initial !important',
  },
  containedSecondary: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#FE2213 !important',
    },
  },
  root: {
    fontSize: 14,
    fontFamily: brandStyles.typography.fontFamilyBody,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
    padding: '10px 16px 6px 16px',
  },
};

// d & m
theme.typography.h6 = {
  fontSize: 14,
  fontFamily: brandStyles.typography.fontFamilyTitle,
  fontWeight: 'bold',
  lineHeight: '18px',
  [theme.breakpoints.up('md')]: {
    fontSize: 18,
    lineHeight: '24px',
  },
};

// d & m
theme.typography.body1 = {
  fontSize: brandStyles.typography.mobile.fontSizeBody1,
  fontFamily: brandStyles.typography.fontFamilyBody,
  width: '100%',
  lineHeight: '22px',
  [theme.breakpoints.up('md')]: {
    fontSize: brandStyles.typography.desktop.fontSizeBody1,
    lineHeight: '27px',
  },
};

// d & m
theme.typography.body2 = {
  fontSize: brandStyles.typography.mobile.fontSizeBody2,
  fontFamily: brandStyles.typography.fontFamilyBody,
  width: '100%',
  lineHeight: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: brandStyles.typography.desktop.fontSizeBody2,
    lineHeight: '24px',
  },
};

const DefaultTheme = ({ children, layout }) => {
  theme.typography.h1 = {
    fontSize: 35,
    color: brandStyles.colors.palette.primary.main,
    fontFamily: brandStyles.typography.fontFamilyTitle,
    lineHeight: '32px',
    paddingTop: '30px',
    paddingBottom: '30px',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      lineHeight: '64px',
    },
  };

  theme.typography.h2 = {
    fontSize: 24,
    fontFamily: brandStyles.typography.fontFamilyTitle,
    lineHeight: '18px',
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
      lineHeight: '36px',
    },
  };

  theme.typography.h3 = {
    fontSize: 18,
    fontFamily: brandStyles.typography.fontFamilyTitle,
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: 26,
      lineHeight: '40px',
    },
  };

  theme.typography.h4 = {
    fontSize: 20,
    fontFamily: brandStyles.typography.fontFamilyTitle,
    lineHeight: '24px',
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  };

  theme.typography.h5 = {
    fontSize: 16,
    fontFamily: brandStyles.typography.fontFamilyTitle,
    lineHeight: '24px',
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      lineHeight: '28px',
    },
  };

  theme.spacing.body = 16;

  theme.overrides.MuiButton = {
    label: {
      alignItems: 'initial !important',
    },
    outlined: {
      border: `1px solid ${brandStyles.colors.palette.primary.main} !important`,
      color: brandStyles.colors.palette.primary.main,
      padding: '9px 15px 5px 15px',
      '&:hover': {
        backgroundColor: brandStyles.colors.button.outlined.hover.backgroundColor,
        color: brandStyles.colors.button.outlined.hover.color,
      },
    },
    outlinedSecondary: {
      '&:hover': {
        backgroundColor: `${brandStyles.colors.button.outlined.hover.backgroundColor} !important`,
      },
    },
    containedSecondary: {
      backgroundColor: brandStyles.colors.button.contained.backgroundColor,
      border: `1px solid ${brandStyles.colors.button.contained.backgroundColor}`,
      color: brandStyles.colors.button.contained.color,
      '&:hover': {
        backgroundColor: `${brandStyles.colors.button.contained.hover.backgroundColor} !important`,
        borderColor: brandStyles.colors.button.contained.hover.borderColor,
        color: brandStyles.colors.button.contained.hover.color,
      },
    },
    root: {
      fontSize: 14,
      fontFamily: brandStyles.typography.fontFamilyBody,
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
      },
      padding: '10px 16px 6px 16px',
    },
  };

  theme.overrides.MuiPickersDay = {
    day: {
      color: '#000',
      paddingLeft: '2px',
      paddingTop: '4px',
    },
    current: {
      color: '#000',
    },
    daySelected: {
      borderRadius: '36px',
      border: '3px solid #43aa4c',
      color: 'inherit',
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DefaultTheme;
