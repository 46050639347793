import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import BackButton from '../subcomponents/BackButton';
import Divider from '@material-ui/core/Divider';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import { clearData } from '../../store/actions/availabilityActions';
import { clearDataAppointment } from '../../store/actions/appointmentActions';
import { clearDataTracking } from '../../store/actions/trackingActions';

import SelectedServiceBox from '../subcomponents/SelectedServiceBox';
import RichText from '../../utils/RichText';
import Cta from '../subcomponents/Cta';

import { getPageTitle } from '../../utils/SEO';

const inlineStyles = theme => ({
  adContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  button: {
    width: '100%',
  },
  expansionPanel: {
    width: '100%',
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing.body,
    paddingRight: theme.spacing.body,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  h3: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  mainContent: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 30,
    },
  },
  note: {
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 31,
      paddingRight: 31,
    },
  },
  dividerWrapper: {
    margin: '20px 0',
  },
  selectedServiceBoxWrapper: {
    order: 0,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
});

/**
 * ThankYou
 * User fills in information and on submit the service will confirm reservation
 * @param {Object} classes    Classes from JSS inlineStyles
 */

const ThankYou = ({
  classes,
  bookingReference,
  appointment,
  t,
  selectedSubService,
  status,
  thankYouPageContents,
  localeLong,
  lang,
  clearData,
  clearDataAppointment,
  clearDataTracking,
  subServiceContents,
  brandSettings,
}) => {
  if (status === 'BOOKED') {
    const specifiedCustomerInstructionsForSubservice = R.path(
      ['fields', `customerInstructionsIn${localeLong}`],
      subServiceContents,
    );
    let customerInstructions =
      specifiedCustomerInstructionsForSubservice !== undefined
        ? specifiedCustomerInstructionsForSubservice
        : R.path([`customerInstructionsIn${localeLong}`], thankYouPageContents);

    return (
      <Grid container direction="row">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle(t('views.thankYou.confirmation'))}</title>
        </Helmet>
        <Grid item xs={12} className={classes.headline + ' ' + classes.paddingLeftRight}>
          <Typography className={classes.h1} variant="h1">
            {R.path([`headlineIn${localeLong}`], thankYouPageContents)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.selectedServiceBoxWrapper}>
          <SelectedServiceBox content={selectedSubService} bookingReference={bookingReference} />
        </Grid>
        <Grid item xs={12} md={8} className={classes.mainContent + ' ' + classes.paddingLeftRight}>
          <Box mb={4}>
            <Typography align="left" variant="body1">
              {R.path([`textContentIn${localeLong}`], thankYouPageContents)}
            </Typography>
          </Box>

          <RichText textData={customerInstructions} />

          <Box my={5}>
            <Divider variant="middle" className={classes.dividerWrapper} />
            <BackButton to={`/${lang}`} forward={true} />
            <Divider variant="middle" className={classes.dividerWrapper} />
          </Box>

          <Grid container spacing={5}>
            {Array.isArray(R.path(['ctaList'], thankYouPageContents)) &&
              R.path(['ctaList'], thankYouPageContents).map(item => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={R.path(['fields', `nameIn${localeLong}`], item)}
                  className={classes.adContainer}>
                  <Cta
                    headline={R.path(['fields', `nameIn${localeLong}`], item)}
                    caption={R.path(['fields', `descriptionIn${localeLong}`], item)}
                    image={R.path(['fields', 'image', 'fields', 'file', 'url'], item)}
                    buttonUrl={R.path(['fields', `urlFor${localeLong}Users`], item)}
                    buttonCaption={R.path(['fields', `buttonTextIn${localeLong}`], item)}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (status === 'PENDING' || status === '') {
    return (
      <Grid container direction="row" spacing={0} alignItems="center">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle(t('views.thankYou.confirmation'))}</title>
        </Helmet>
        <Grid item xs={12} className={classes.headline}>
          <Typography className={classes.h1 + ' ' + classes.paddingLeftRight} variant="h1">
            {t('views.thankYou.reservationInProgress')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.selectedServiceBoxWrapper}>
          <SelectedServiceBox content={selectedSubService} />
        </Grid>
        <Grid item xs={12} md={8} className={classes.textCenter}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (status === 'FAILED') {
    return (
      <Grid container direction="row" spacing={0}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getPageTitle(t('views.thankYou.confirmation'))}</title>
        </Helmet>
        <Grid item xs={12} className={classes.headline + ' ' + classes.paddingLeftRight}>
          <Typography className={classes.h1} variant="h1">
            {t('views.thankYou.reservationFailed')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.selectedServiceBoxWrapper}>
          <SelectedServiceBox content={selectedSubService} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography align="center" variant="body1" className={classes.note}>
            <Button
              disableRipple
              color="secondary"
              variant="contained"
              className={classes.redButton}
              component={NavLink}
              to={`/${lang}`}>
              <strong>{t('views.thankYou.reserveNewTime')}</strong>
            </Button>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (status === 'DENIED') {
    const bookingBlocklistContents = R.path(['bookingBlocklist', 'fields'], brandSettings);
    if (!bookingBlocklistContents) return null;
    const deniedPageTitle = R.path([`titleIn${localeLong}`], bookingBlocklistContents);
    const deniedPageContent = R.path([`contentIn${localeLong}`], bookingBlocklistContents);
    return (
      <Container maxWidth="md">
        <Grid container direction="row" spacing={0}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{getPageTitle(t('views.thankYou.confirmation'))}</title>
          </Helmet>
          <Grid item xs={12} className={classes.headline + ' ' + classes.paddingLeftRight}>
            <Typography className={classes.h1} variant="h1">
              {deniedPageTitle}
            </Typography>

            <RichText textData={deniedPageContent} />
          </Grid>
        </Grid>
      </Container>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  clearData: () => dispatch(clearData()),
  clearDataAppointment: () => dispatch(clearDataAppointment()),
  clearDataTracking: () => dispatch(clearDataTracking()),
});

const mapStateToProps = state => {
  const appointmentGATID = R.path(['availability', 'grandVisionApiId'], state);
  const subServices = R.path(['contentful', 'subServices'], state);

  const subServiceContents = R.head(
    subServices.items.filter(item => item.fields.grandVisionApiId.includes(appointmentGATID)),
  );

  return {
    thankYouPageContents: R.path(['contentful', 'thankYouPage'], state),
    brandSettings: R.path(['contentful', 'layout'], state),
    subServiceContents,
    localeLong: R.path(['contentful', 'localeLong'], state),
    selectedSubService: {
      headline: R.path(['availability', 'subService'], state),
      dateBigger: R.path(['availability', 'day'], state),
      timeBigger: R.path(['availability', 'timeSlotFormatted'], state),
      address: R.path(['availability', 'storeAddress'], state),
      person: R.path(['availability', 'selectedOpticianName'], state),
      endTime: R.path(['availability', 'endTime'], state),
      timeSlot: R.path(['availability', 'timeSlot'], state),
      price: R.path(['availability', 'price'], state),
      storeName: R.path(['availability', 'storeName'], state),
    },
    status: R.path(['appointment', 'status'], state),
    appointment: R.path(['appointment'], state),
    bookingReference: R.path(['appointment', 'bookedAppointment', 'attributes', 'referenceCode'], state),
  };
};

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ThankYou)),
);
