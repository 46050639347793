import React from 'react';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
    priceLabel: {
        fontWeight: 'bold',
        fontSize: 16,
        color: theme.palette.primary.main,
        lineHeight: 1,
        paddingBottom: 6,
    },
    pricePrimary: {
        fontWeight: 'bold'
    },
    priceSecondary: {
        fontSize: 14
    }
});

/**
 * 
 * @param {*} param0 
 * @returns 
 */

const Price = ({t, classes, prices}) => {

    return(
        <div className={classes.priceContainer}>
            {prices.priceLabel &&
                <Typography variant="body1" className={classes.priceLabel}>
                    {prices.priceLabel}
                </Typography>
            }
            <Typography variant="body1" className={classes.pricePrimary}>
                {prices.primaryPrice}
            </Typography>
                    
            {prices.secondaryPrice &&
                <Typography variant="body2" className={classes.priceSecondary} gutterBottom>
                    ({t('views.landing.priceNormally')} {prices.secondaryPrice})
                </Typography>
            }
        </div>
    )
}

export default withStyles(inlineStyles)(withTranslation('translations')(Price));

