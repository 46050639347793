import * as R from 'ramda';
import * as moment from 'moment';
import * as rax from 'retry-axios';
import axios from 'axios';

/**
 * UserException : Create an exception class UserException for throwing
 * @param {string} code       Error object property : code (not documented)
 * @param {string} message    Error object property : message
 */
export class UserException {
  constructor(code, message) {
    this.message = message;
    this.code = code;
  }
  // Make the exception convert to a pretty string when used as
  // a string (e.g. by the error console)
  // override
  toString() {
    return `${this.code}: "${this.message}"`;
  }
}

/**
 * Do a fetch request to supplied URL for multiple stores fetch
 * @param {string} url
 */
const doRequestMultipleStores = async url => {
  return handleRequest('gatRequest', url);
};

/**
 * Generalized retried Axios call
 * @param {string} handler      Lambda request endpoint
 * @param {string} url          GAT request endpoint
 * @param {*} gatMethod         GAT request method
 * @param {*} gatBody           GAT request body
 */
const handleRequest = async (handler, url, gatMethod, gatBody) => {
  const reqBody = gatBody
    ? JSON.stringify({ endpoint: url, gatMethod: gatMethod || 'GET', gatBody: gatBody })
    : JSON.stringify({ endpoint: url, gatMethod: gatMethod || 'GET' });

  let res;

  let requestObject = {
    url: `/.netlify/functions/${handler}`,
    method: 'post', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-store', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client,
  };

  requestObject['data'] = url && reqBody;

  rax.attach();

  try {
    const retryConfig = {
      raxConfig: {
        retry: 0,
        httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
        noResponseRetries: 0,
        onRetryAttempt: err => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
        },
      },
      timeout: 5000,
    };
    requestObject['raxConfig'] = retryConfig.raxConfig;
    res = await axios(requestObject);
    return res.data;
  } catch (err) {
    console.log('ERROR', err);
    throw new UserException(err.code, err.message);
  }
};

/**
 * Create customer
 * @param {object} values      Form values
 * @returns {promise}
 */
export const createCustomer = async (values, dateOfBirth) => {
  const dissocValues = R.dissoc('personalID', values);

  const gatBody = {
    data: {
      type: 'customers',
      attributes: {
        ...dissocValues,
        dateOfBirth: dateOfBirth,
        fields: [
          { label: 'customer.ssn', value: R.path(['personalID'], values) },
          { label: 'privacy.terms', value: 'true' },
        ],
      },
    },
  };

  try {
    const url = 'customers';
    const res = await handleRequest('gatRequest', url, 'post', gatBody);
    console.log('CUSTOMER RES', res);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Create appointment
 * @param {object} values      Form values
 * @returns {promise}
 */
export const createAppointment = async (
  values,
  results,
  appointmentType,
  selectedStoreId,
  selectedOptician,
  startTime,
  endTime,
  shortBookingRef,
  preferredChannelOfCommunication,
  language,
) => {
  let gatBody = {
    data: {
      type: 'appointments',
      attributes: {
        bookingChannel: 'frontend',
        notificationRequired: true,
        manualOverride: false,
        preferredLanguage: language,
        preferredChannelOfCommunication: preferredChannelOfCommunication,
        site: 'appointment',
        createdBy: 'anonymous',
        customerComment: '',
        startTime: moment(startTime).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(endTime).format('YYYY-MM-DDTHH:mm'),
      },
      relationships: {
        customer: {
          data: {
            type: 'customers',
            id: R.path(['data', 'id'], results),
          },
        },
        store: {
          data: {
            type: 'stores',
            id: selectedStoreId,
          },
        },
        appointmentType: {
          data: {
            type: 'appointment-types',
            id: appointmentType,
          },
        },
      },
    },
  };

  if (selectedOptician) {
    gatBody.data.relationships.consumerSelectedOptician = {
      data: {
        type: 'opticians',
        id: selectedOptician,
      },
    };
  }

  try {
    const url = 'appointments';
    const res = await handleRequest('gatRequest', url, 'post', gatBody);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get appointment during appointment creation
 * Supports automated polling for PENDING state
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getAppointmentDuringCreation = async values => {
  const appointmentId = R.path(['data', 'id'], values);

  try {
    const url = `appointments/${appointmentId}`;
    const res = await handleRequest('gatRequestPolling', url, 'get');
    console.log('GET APPOINTMENT RES', res);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get appointment during cancellation with included customer values
 * Supports included customer values
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getAppointmentDuringLogin = async values => {
  const bookingReference = R.path(['bookingref'], values).replace(/\s/g, '');
  const customerSSN = R.path(['ssn'], values).replace(/\s/g, '');

  const gatBody = {
    customerSSN: customerSSN,
  };

  try {
    const url = `appointments?filter[referenceCode]=${bookingReference}&include=customer,appointmentType,store,optician`;
    const res = await handleRequest('gatRequestWithCheck', url, 'get', gatBody);
    console.log('GET APPOINTMENT FOR CANCELLATION RES', res);
    return res;
  } catch (err) {
    //throw new UserException(err.code, err.message);
  }
};

/**
 * Cancel appointment
 * @param {object} values      Form values
 * @returns {promise}
 */
export const cancelAppointment = async values => {
  const gatBody = {
    data: {
      type: 'appointments',
      attributes: {
        status: 'CANCEL',
      },
    },
  };

  try {
    console.log('CANCEL VALUES', values);

    const url = `appointments/${values}`;
    const res = await handleRequest('gatRequest', url, 'patch', gatBody);
    console.log('RES CANCEL', res);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get Datetime
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getDateTime = async values => {
  try {
    const url = 'appointment-types';
    const res = await handleRequest('datetime', url, 'get');
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get Datetime
 * @param {object} values      Form values
 * @returns {promise}
 */

/**
 * Log booking status to Netlify function log.
 * @param {string} ip         IP address of the user
 * @param {string} email      Email address of the user
 * @param {string} phone      Phone number of the user
 * @param {Date} timestamp    Timestamp when the appointment booking was commenced
 * @returns
 */
export const logBookingStatus = async (ip, email, phone, timestamp) => {
  try {
    const url = 'appointment-types';
    const gatBody = {
      data: {
        type: 'Booking blocked',
        userData: {
          ip,
          email,
          phone,
          timestamp,
        },
      },
    };
    const res = await handleRequest('bookingStatus', url, 'post', gatBody);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get All Appointment Types
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getAppointmentTypes = async values => {
  try {
    const url = 'appointment-types';
    const res = await handleRequest('gatRequest', url, 'get');
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get Appointment Types Based On Appointment Group
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getAppointmentTypesBasedOnAppointmentGroup = async values => {
  const appointmentGroupName = R.path(['appointmentGroupName'], values);

  try {
    const params = `filter[additionalTags.appointmentGroup]=${appointmentGroupName}`;
    const url = `appointment-types?${params}`;
    const res = await handleRequest('gatRequest', url, 'get');
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get All Stores
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getAllStores = async values => {
  try {
    const url = 'stores?filter[code][neq]=9999&filter[active]=true&page[limit]=1000';
    const storeResponse = await handleRequest('gatRequest', url);
    return storeResponse;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get Specific Store
 * Unused for now
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getSpecificStore = async values => {
  const storeId = R.path(['storeId'], values);

  try {
    const params = `filter[store]=${storeId}`;
    const url = `stores?${params}`;
    const res = await handleRequest('gatRequest', url, 'get');
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get specialist by ID
 * @param {string} id
 * @returns {object}
 */
export const getSpecialistById = async id => {
  try {
    const url = `opticians?filter[active]=true&filter[id]=${id}`;
    const res = await handleRequest('gatRequest', url);
    return res;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get specialists by store and service ids
 * @param {array} nearbyStores
 * @param {string} selectedServiceID
 * @returns
 */
export const getSpecialists = async (nearbyStores, selectedServiceID) => {
  const services = selectedServiceID.split(',');

  try {
    let callStackOpticians = [];
    let storeIds = [];
    let opticianParams;

    nearbyStores.forEach(store => {
      services.forEach(service => {
        opticianParams = `filter[active]=true&filter[opticianWorkTimes.store.id]=${store.storeId}&filter[associateGroups.appointmentTypes.id]=${service}`;
        callStackOpticians.push(`opticians?${opticianParams}`);
      });
      storeIds.push(store.storeId);
    });

    const actionsOpticians = callStackOpticians.map(doRequestMultipleStores);
    const opticians = await Promise.all(actionsOpticians);

    return {
      opticians: opticians,
      storeIds: storeIds,
    };
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};

/**
 * Get Availability and opticians for multiple stores
 *
 * @param {object} values      Form values
 * @returns {promise}
 */
export const getAvailabilityForMultipleStores = async (
  values,
  nearbyStores,
  selectedServiceID,
  selectedOptician,
  date,
) => {
  // If no stores are selected - won't fetch availability.
  if (!nearbyStores) return;

  const maxRequestedNumberOfDays = 30;

  const startDateFull = date ? moment(date) : moment();

  const startDate = startDateFull.format('YYYY-MM-DD');

  const daysInMonth = startDateFull.daysInMonth();
  const daysToFetch = daysInMonth - startDateFull.get('date') + 1;

  const secondDate =
    daysToFetch > maxRequestedNumberOfDays
      ? startDateFull.add(maxRequestedNumberOfDays, 'days').format('YYYY-MM-DD')
      : '';

  const formRequestURL = (storeId, service, startDate, numberOfDays) => {
    const opticianFilter = selectedOptician ? `filter[optician]=${selectedOptician}&` : '';
    return `time-slots?${opticianFilter}filter[store]=${storeId}&filter[appointmentType]=${service}&filter[startDate]=${startDate}&filter[requestedNumberOfDays]=${numberOfDays}`;
  };

  try {
    let params;
    let opticianParams;
    let stores = [];
    let appointmentTypes = [];
    let callStack = [];
    let callStackOpticians = [];

    const services = selectedServiceID.split(',');

    nearbyStores.forEach(item => {
      services.forEach(service => {
        callStack.push(
          formRequestURL(
            item.storeId,
            service,
            startDate,
            daysToFetch > maxRequestedNumberOfDays ? maxRequestedNumberOfDays : daysToFetch,
          ),
        );
        secondDate &&
          callStack.push(formRequestURL(item.storeId, service, secondDate, daysToFetch - maxRequestedNumberOfDays));
        stores.push(item.storeId);
        appointmentTypes.push(service);
      });
    });

    const actions = callStack.map(doRequestMultipleStores);
    let results = await Promise.all(actions);

    nearbyStores.forEach(item => {
      services.forEach(service => {
        opticianParams = `filter[active]=true&filter[opticianWorkTimes.store.id]=${item.storeId}&filter[associateGroups.appointmentTypes.id]=${service}`;
        callStackOpticians.push(`opticians?${opticianParams}`);
      });
    });

    let resultsOpticians = [];
    const eyeDoctorServiceIDs = ['3001414', '3000007', '3000072'];

    if (eyeDoctorServiceIDs.includes(selectedServiceID)) {
      const actionsOpticians = callStackOpticians.map(doRequestMultipleStores);
      resultsOpticians = await Promise.all(actionsOpticians);
    }

    let resultsCombined = results;

    if (secondDate) {
      resultsCombined = [];
      for (let i = 0; i < results.length; i += 2) {
        let arr1 = Object.values(results[i])[0];
        let arr2 = Object.values(results[i + 1])[0];
        resultsCombined.push({ data: arr1.concat(arr2) });
      }
    }

    resultsCombined = resultsCombined.map((item, nth) => {
      return {
        ...item,
        storeId: stores[nth],
        appointmentType: appointmentTypes[nth],
        opticians: resultsOpticians.length > 0 ? resultsOpticians[nth].data : resultsOpticians,
      };
    });

    return resultsCombined;
  } catch (err) {
    throw new UserException(err.code, err.message);
  }
};
